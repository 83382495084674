import React, {
  useEffect,
  useRef,
  useCallback
} from 'react';

const headingAccentStyles = {
  // color: "#fc31ac",
  transition: "all 5s ease",
  WebkitTransition: "all 5s ease",
  MozTransition: "all 5s ease"
}

export default function Hero ({children}) {
    const animatedText = useRef(null);

    const runAnimation = useCallback(elem => {
    const currColour = elem.style.color;
    elem.style.color = (currColour === 'rgb(252, 49, 172)' && 'rgb(253, 153, 107)') || 'rgb(252, 49, 172)';
  }, []);

  useEffect(() => {
    const animationInterval = setInterval(() => {
      runAnimation(animatedText.current);
    }, 5000);
    
    return () => {
      clearInterval(animationInterval);
    }
  }, [runAnimation]);

  return (
    <span style={headingAccentStyles} ref={animatedText}>{children}</span>
  )
}